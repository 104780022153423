import * as React from 'react'

import { GatsbyImage } from 'gatsby-plugin-image'

import LinkComponent from '../compat/Link'
import { resizeImageData } from '../../util/images'

import {
  articles,
  excerpt,
  journalFeatureImage
} from './articlesExcerpt.module.scss'

export default class ArticlesExcerpt extends React.Component {

  resizeFeatureImageData(imageData) {
    const desktopMinPixels = 1024
    const sizes = `(min-width: ${desktopMinPixels}px) 50vw, 100vw`
    return resizeImageData(imageData, sizes);
  }

  render() {
    const Link = this.props.Link || LinkComponent;

      return (
        <div className={`content ${articles}`}>
            {
              this.props.articles
                .map(node => (
                    <article key={node.id} className={ `block is-clearfix ${excerpt}` }>
                        {
                        (node.featureImage ?
                            (
                            <figure className={ journalFeatureImage }>
                              <GatsbyImage image={ this.resizeFeatureImageData(node.featureImage.gatsbyImageData) } alt="" />
                                <figcaption>{ node.featureImage.description ? node.featureImage.description : "" }</figcaption>
                            </figure>
                            ) : null)
                        }
                        <h3>{node.title}</h3>
                        <p>{node.displayDateLine}</p>
                        <p>{node.parent.excerpt}</p>
                        <Link to={ node.url }>Read more</Link>
                    </article>
                ))
            }
        </div>)
    }

}
