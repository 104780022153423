import * as React from 'react'

import { GatsbyImage } from 'gatsby-plugin-image'

import LinkComponent from '../compat/Link'
import { resizeImageData } from '../../util/images'

import {
  articles
} from './articlesCard.module.scss'

export default class ArticlesCard extends React.Component {

  resizeFeatureImageData(imageData) {
    const desktopMinPixels = 1024
    const sizes = `(min-width: ${desktopMinPixels}px) 50vw, 100vw`
    return resizeImageData(imageData, sizes);
  }

  renderCard(node) {
    const Link = this.props.Link || LinkComponent;
    return (
      <article className='card'>
        <Link to={ node.url } className='card'>
              {
              (node.featureImage ?
                  (
                  <figure className={ 'card-image' }>
                    <GatsbyImage image={ this.resizeFeatureImageData(node.featureImage.gatsbyImageData) } alt="" />
                  </figure>
                  ) : null)
              }
              <div className="card-content content">
                  <h6>{node.pageType.toUpperCase()}</h6>
                  <h4>{node.title}</h4>
                  <p>{node.parent.excerpt}</p>
              </div>
        </Link>
      </article>
    )
  }

  render() {
    let placeholderIndex=0
      return (
        <div className={`columns is-multiline is-centered ${articles}`}>
            {
              this.props.articles
                .map(node => {
                  const key = node ? node.id : `placeholder-${placeholderIndex++}`
                  return (
                    <div key={key} className='column is-one-third-desktop is-half-tablet is-self-align-stretch' >
                      { node ? this.renderCard(node) : null}
                    </div>
                  )
                })
            }
        </div>
      )
  }
}
