import * as React from 'react'
import PageLayout from '../components/layout/PageLayout'
import Map from '../components/map/Map'
import { graphql } from 'gatsby'
import ArticlesCard from '../components/articles/ArticlesCard'
import ArticlesExcerpt from '../components/articles/ArticlesExcerpt'
import ContentSection from '../components/markdown/ContentSection'
import LayoutSection from '../components/layout/LayoutSection'

import { MediaModalControls } from '../components/modal/MediaModalControls'

const IndexPage = ({data}) => {

  const page = data.mdx.childStaticPage;
  const body = data.mdx.body
  const media = data.allMediaAsset.nodes
  return (
    <PageLayout
      pageTitle={ page.title }
      image={ page.featureImage }
      editUrl={ page.editUrl } >

      <MediaModalControls media={ media }>
        <LayoutSection id='map-section'>
          <Map recordings={ data.allLocationRecording.nodes } photos={media}/>
        </LayoutSection>

        <ContentSection body={body} />

        <LayoutSection id='recent-tours'>
          <ArticlesCard articles={ data.allTour.nodes } />
        </LayoutSection>

        <LayoutSection id='recent-posts'>
          <ArticlesExcerpt articles={ data.allTourJournal.nodes } />
        </LayoutSection>
      </MediaModalControls>

    </PageLayout>
  )
}

export const query = graphql`
query {
  mdx(slug: {eq: "home"}) {
    body
    childStaticPage {
      ...PageMetadata
    }
  }
  allLocationRecording {
    nodes {
      ...LocationRecordingSummary
    }
  }
  allTour(filter: {status: {eq: COMPLETE}}) {
    nodes {
      ...PageMetadata
      ...PageSnippet
    }
  }
  allTourJournal(sort: {fields: startDate, order: DESC}, limit: 12, skip: 0) {
    nodes {
      ...PageMetadata
      ...PagePreview
    }
  }
  allMediaAsset(
    filter: {priority: {gte: 3}}
    sort: {fields: createDateTime, order: ASC}
  ) {
    nodes {
      ...MediaData
    }
  }
}
`

export default IndexPage
